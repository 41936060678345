import { render, staticRenderFns } from "./TrackingRuleNotifications.vue?vue&type=template&id=c5b68178&scoped=true&"
import script from "./TrackingRuleNotifications.vue?vue&type=script&lang=js&"
export * from "./TrackingRuleNotifications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b68178",
  null
  
)

export default component.exports