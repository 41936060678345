<template>
  <div :class="['accordion-content', { open: context.isOpen }]">
    <div class="inner">
      <transition name="slide-fade" mode="out-in">
        <div v-if="context.isOpen">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["context"],
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.accordion-content.open {
  grid-template-rows: 1fr;
}

.inner {
  overflow: hidden;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: grid-template-rows 0.5s ease-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  grid-template-rows: 0fr;
}
</style>
