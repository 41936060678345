<template>
  <table>
    <thead>
      <th>Checkpoint</th>
      <th>
        Sms Customer
        <CheckInput
          :title="$t('selectAll')"
          v-model="isAllColumnSmsCustomerSelected"
          @input="
            onSelectAllNotification(NotificationType.SMS_CUSTOMER, $event)
          "
          :html-for="`sms-customer-${group.zoneCode}`"
        />
      </th>
      <th>
        Whatsapp Customer
        <CheckInput
          :title="$t('selectAll')"
          v-model="isAllColumnWhatsappCustomerSelected"
          :html-for="`whatsapp-customer-${group.zoneCode}`"
          @input="
            onSelectAllNotification(NotificationType.WHATSAPP_CUSTOMER, $event)
          "
        />
      </th>
      <th>
        Email Customer
        <CheckInput
          :title="$t('selectAll')"
          v-model="isAllColumnEmailCustomerSelected"
          :html-for="`email-customer-${group.zoneCode}`"
          @input="
            onSelectAllNotification(NotificationType.EMAIL_CUSTOMER, $event)
          "
        />
      </th>
      <th>
        Email Seller
        <CheckInput
          :title="$t('selectAll')"
          v-model="isAllColumnEmailSellerSelected"
          :html-for="`email-seller-${group.zoneCode}`"
          @input="
            onSelectAllNotification(NotificationType.EMAIL_SELLER, $event)
          "
        />
      </th>
      <th>
        Webhook Seller
        <CheckInput
          :title="$t('selectAll')"
          v-model="isAllColumnWebhookSellerSelected"
          :html-for="`webhook-seller-${group.zoneCode}`"
          @input="
            onSelectAllNotification(NotificationType.WEBHOOK_SELLER, $event)
          "
        />
      </th>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
import { NotificationType } from "../../../constants";
import CheckInput from "./CheckInput.vue";

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  components: {
    CheckInput,
  },
  data() {
    return {
      isAllColumnSmsCustomerSelected: false,
      isAllColumnWhatsappCustomerSelected: false,
      isAllColumnEmailCustomerSelected: false,
      isAllColumnEmailSellerSelected: false,
      isAllColumnWebhookSellerSelected: false,
      NotificationType,
    };
  },
  watch: {
    "group.checkpoints": {
      handler() {
        this.setIsCheckedColumns();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSelectAllNotification(type, value) {
      this.$emit("selectAllColumn", { type, value });
    },
    checkIfColumnIsSelected(notificationType) {
      return this.group.checkpoints.every(
        (checkpoint) => checkpoint.notifications[notificationType] === true
      );
    },
    setIsCheckedColumns() {
      this.isAllColumnSmsCustomerSelected = this.checkIfColumnIsSelected(
        NotificationType.SMS_CUSTOMER
      );
      this.isAllColumnWhatsappCustomerSelected = this.checkIfColumnIsSelected(
        NotificationType.WHATSAPP_CUSTOMER
      );
      this.isAllColumnEmailCustomerSelected = this.checkIfColumnIsSelected(
        NotificationType.EMAIL_CUSTOMER
      );
      this.isAllColumnEmailSellerSelected = this.checkIfColumnIsSelected(
        NotificationType.EMAIL_SELLER
      );
      this.isAllColumnWebhookSellerSelected = this.checkIfColumnIsSelected(
        NotificationType.WEBHOOK_SELLER
      );
    },
  },
  mounted() {
    this.setIsCheckedColumns();
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  thead {
    position: sticky;
    top: -0.5rem;
    z-index: 5;
    background-color: var(--white);
    border-bottom: 2px solid rgb(213, 219, 230);
    th {
      padding: 0.5rem 0;
      font-size: 0.75rem;
    }
  }
  tr {
    &:nth-child(even) {
      background-color: var(--gray-05);
    }
    td {
      padding: 0.5rem 0;
    }
  }
}
</style>
