<template>
  <div>
    <div class="d-flex justify-content-md-between">
      <NbPageTitle :title="$t('trackingRuleNotificationsPage.title')" />
      <NbButton
        @click="isModalCreateOpen = true"
        class="d-flex align-items-center text-left gap-2"
      >
        <NbIcon icon="plus" size="18" />
        {{ $t("trackingRuleNotificationsPage.addConfig") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="trackingRuleNotificationSettings"
        namespace="tracking_notification_rules"
        ref="trackingRuleNotificationSettingsRef"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalRules = $event"
        @clickedRow="showRule($event)"
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>

        <template #cell(last_update)="data">
          {{ data.item.last_update | dateTime }}
        </template>

        <template #cell(notifications)="data">
          {{
            $t("trackingRuleNotificationsPage.notifsConfigured", {
              total: data.item.notification_elements_count,
            })
          }}
        </template>

        <template #cell(active)="data">
          <IsCheckedIcon :checked="data.item.active" />
        </template>

        <template #cell(conditions)="data">
          {{
            isAllContracts(data.item)
              ? $t("trackingRuleNotificationsPage.allContracts")
              : $t("trackingRuleNotificationsPage.totalContracts", {
                  total: data.item.contract_ids.length,
                })
          }}
        </template>

        <template #cell(actions)="data">
          <div>
            <NbButton variant="tertiary" @click="showRule(data.item)">
              <NbIcon icon="edit" />
              {{ $t("edit") }}
            </NbButton>
            <NbButton variant="tertiary" @click="onRemove(data.item)">
              <NbIcon icon="trash" />
              {{ $t("remove") }}
            </NbButton>
            <NbButton variant="tertiary" @click="onEnableOrDisable(data.item)">
              <NbIcon :icon="data.item.active ? 'lock' : 'unlock'" />
              {{ data.item.active ? $t("disable") : $t("enable") }}
            </NbButton>
          </div>
        </template>
      </NbTablev2>
    </section>

    <Modal v-model="isModalCreateOpen" size="lg">
      <Spinner v-if="loadingCheckpoints" size="lg" class="mx-auto" />
      <TrackingRuleNotificationsUpsert v-else @success="refreshTable" />
    </Modal>

    <Modal v-model="isModalUpdateOpen" size="lg" @close="selectedRule = null">
      <Spinner v-if="loadingCheckpoints" size="lg" class="mx-auto" />

      <TrackingRuleNotificationsUpsert
        v-else
        :rule="selectedRule"
        @success="refreshTable"
      />
    </Modal>

    <Modal v-model="isRemoveModalOpen" @close="selectedRule = null">
      <TrackingRuleNotificationsRemove
        :rule="selectedRule"
        @success="refreshTable"
      />
    </Modal>

    <Modal v-model="isToggleStatusModalOpen" @close="selectedRule = null">
      <TrackingRuleNotificationsToggleStatus
        :rule="selectedRule"
        @success="refreshTable"
      />
    </Modal>

    <Modal v-model="isNoPermissionToCreateModalOpen" @close="redirectToHome">
      <ModalHeader>
        <ModalTitle>{{ $t("warning") }}</ModalTitle>
      </ModalHeader>

      <p>
        {{
          $t("trackingRuleNotificationsPage.platformWillBeInCharge", {
            platform,
          })
        }}
      </p>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import TrackingRuleNotificationsUpsert from "./TrackingRuleNotificationsUpsert.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import TrackingRuleNotificationsRemove from "./TrackingRuleNotificationsRemove.vue";
import TrackingRuleNotificationsToggleStatus from "./TrackingRuleNotificationsToggleStatus.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import SellerService from "@/services/SellerService.js";

import CheckpointService from "@/services/CheckpointService";
import moment from "moment";
import Spinner from "@/components/Spinner.vue";
const checkpointService = new CheckpointService();

const sellerService = new SellerService();

export default {
  components: {
    NbPageTitle,
    NbButton,
    NbIcon,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    TrackingRuleNotificationsUpsert,
    NbTablev2,
    NbTabs,
    TrackingRuleNotificationsRemove,
    TrackingRuleNotificationsToggleStatus,
    IsCheckedIcon,
    Spinner,
  },
  filters: {
    dateTime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data() {
    return {
      currentTab: "all",
      totalRules: 0,
      fields: [],
      isModalCreateOpen: false,
      isModalUpdateOpen: false,
      isRemoveModalOpen: false,
      isToggleStatusModalOpen: false,
      isSellersPermissionModalOpen: false,
      isNoPermissionToCreateModalOpen: false,
      selectedRule: null,
      sellers: {
        elements: [],
        ids: [],
        count: 0,
      },
      loadingSellers: false,
      loadingCheckpoints: false,
    };
  },
  provide() {
    const ruleSettingsContext = {};

    Object.defineProperty(ruleSettingsContext, "sellers", {
      enumerable: true,
      get: () => this.sellers,
    });

    Object.defineProperty(ruleSettingsContext, "loading", {
      enumerable: true,
      get: () => this.loadingSellers,
    });

    return { ruleSettingsContext };
  },
  computed: {
    platform() {
      return this.$helpers.getPlatform();
    },
    allFields() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: this.$t("name"),
        },
        {
          key: "created_at",
          label: this.$t("creationDate"),
        },
        {
          key: "last_update",
          label: this.$t("lastUpdate"),
        },
        {
          key: "active",
          label: this.$t("active"),
        },
        {
          key: "conditions",
          label: this.$t("trackingRuleNotificationsPage.conditions"),
        },
        {
          key: "notifications",
          label: this.$t("trackingRuleNotificationsPage.notifications"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ];
    },
    filterOptions() {
      return [];
    },
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalRules,
        },
      ];
    },
  },
  methods: {
    redirectToHome() {
      this.$router.push("/");
    },
    refreshTable() {
      this.$refs.trackingRuleNotificationSettingsRef?.getData();
    },
    isAllContracts(rule) {
      return rule.contract_ids?.[0] === "all";
    },
    showRule(rule) {
      this.selectedRule = rule;
      this.isModalUpdateOpen = true;
    },
    onRemove(rule) {
      this.selectedRule = rule;

      this.isRemoveModalOpen = true;
    },
    onEnableOrDisable(rule) {
      this.selectedRule = rule;

      this.isToggleStatusModalOpen = true;
    },
    async loadSellers() {
      try {
        this.loadingSellers = true;
        const { data } = await sellerService.getSellers();
        this.sellers = data.data;
      } finally {
        this.loadingSellers = false;
      }
    },
    async loadCheckpoints() {
      try {
        this.loadingCheckpoints = true;
        const { data } = await checkpointService.getAllCheckpoints();
        this.$store.commit(
          "notificationRuleSettings/UPDATE_CHECKPOINTS",
          data.data.elements
        );
      } finally {
        this.loadingCheckpoints = false;
      }
    },
    async checkSellerPermission() {
      const { data } = await sellerService.getCurrentSeller();

      if (data.data?.notification_rules_permission === "admin_settings") {
        this.isNoPermissionToCreateModalOpen = true;
        return;
      }

      this.loadCheckpoints();
    },
  },
  created() {
    this.checkSellerPermission();
  },
};
</script>

<style lang="scss" scoped></style>
