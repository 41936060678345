<template>
  <div>
    <ModalHeader>
      <ModalTitle>{{ $t("confirm") }}</ModalTitle>
    </ModalHeader>

    <p class="body-4">
      {{
        rule.active
          ? $t("trackingRuleNotificationsPage.wouldYouLikeToDisableRule")
          : $t("trackingRuleNotificationsPage.wouldYouLikeToEnableRule")
      }}
      <strong>{{ rule.name }}</strong
      >?
    </p>

    <p class="body-4">
      {{
        rule.active
          ? $t("trackingRuleNotificationsPage.disableRuleMessage")
          : $t("trackingRuleNotificationsPage.enableRuleMessage")
      }}
    </p>

    <div>
      <p class="text-danger" v-for="error in errors" :key="error">
        {{ error }}
      </p>
    </div>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose>{{ $t("cancel") }}</ModalClose>
      <NbButton :disabled="loading" @click="toggle">{{
        rule.active ? $t("disable") : $t("enable")
      }}</NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalHeader.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
      errors: [],
    };
  },
  methods: {
    async toggle() {
      try {
        this.loading = true;
        await trackingRuleNotificationsService.toggleActivation(this.rule.id, {
          active: !this.rule.active,
        });
        this.$emit("success");
        this.context?.close();
      } catch (error) {
        const errorMessages = error?.response?.data?.messages;

        if (errorMessages?.length) {
          if (Array.isArray(errorMessages)) {
            const errorData = errorMessages.map((error) => {
              if (!Array.isArray(error)) {
                return Object.values(error)?.flatMap((item) => item);
              }

              return error;
            });

            this.errors = errorData?.flat();
            return;
          }

          this.errors = [errorMessages];
          return;
        }
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
