<template>
  <div>
    <h3 class="heading-4 my-4">
      {{ $t("trackingRuleNotificationsPage.createOrUseExistingRule") }}
    </h3>

    <div class="grid grid-cols-2 gap-4 my-4">
      <input
        type="radio"
        id="create"
        v-model="useExistingConfiguration"
        :value="false"
      />
      <label for="create">
        {{ $t("trackingRuleNotificationsPage.createFromScratch") }}
      </label>

      <input
        type="radio"
        id="use"
        v-model="useExistingConfiguration"
        :value="true"
        @change="onSelectExistingRule()"
      />
      <label for="use">
        {{ $t("trackingRuleNotificationsPage.useExistingRule") }}
      </label>
    </div>

    <Spinner v-if="loadingRules" class="mx-auto" size="lg" />
    <NbSelectInput
      v-if="useExistingConfiguration"
      v-model="selectedRuleId"
      :options="rulesOptions"
      :name="$t('trackingRuleNotificationsPage.selectRuleToUse')"
      id="setup"
      :placeholder="$t('selectAnOption')"
    />

    <div class="d-flex justify-content-between mt-4">
      <NbButton variant="secondary" @click="goBack()">
        {{ $t("back") }}
      </NbButton>
      <NbButton :disabled="!isValid || loading" @click="selectValidation">
        {{ $t("next") }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import { mapState, mapMutations } from "vuex";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";
import Spinner from "@/components/Spinner.vue";
import { mapActions } from "vuex/dist/vuex.common.js";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();

export default {
  components: {
    NbButton,
    NbSelectInput,
    Spinner,
  },
  data() {
    return {
      loading: false,
      loadingRules: false,
      selectedRuleId: null,
      useExistingConfiguration: false,
      rulesOptions: [],
    };
  },
  computed: {
    ...mapState({
      ruleName: (state) => state.notificationRuleSettings.ruleName,
    }),
    isValid() {
      if (!this.useExistingConfiguration) {
        return true;
      }

      return !!this.selectedRuleId;
    },
  },
  methods: {
    ...mapMutations("notificationRuleSettings", {
      updateRuleName: "UPDATE_RULE_NAME",
    }),
    ...mapActions({
      setNotificationElements:
        "notificationRuleSettings/setNotificationElements",
    }),
    onSelectExistingRule() {
      if (this.useExistingConfiguration && !this.rulesOptions.length) {
        this.loadRules();
      }
    },
    goBack() {
      this.$emit("back");
    },
    async loadSelectedRule() {
      try {
        this.loading = true;
        const { data } = await trackingRuleNotificationsService.getById(
          this.selectedRuleId
        );
        this.setNotificationElements(data.data?.elements || []);
        this.goNext();
      } finally {
        this.loading = false;
      }
    },
    selectValidation() {
      if (!this.useExistingConfiguration) {
        this.goNext();
        return;
      }

      this.loadSelectedRule();
    },
    async goNext() {
      this.$emit("next");
    },
    async loadRules() {
      try {
        this.loadingRules = true;
        const { data } = await trackingRuleNotificationsService.getRules({
          offset: 0,
          limit: 10000,
        });
        this.rulesOptions = data.data.elements.map((rule) => ({
          text: rule.name,
          value: rule.id,
        }));
      } finally {
        this.loadingRules = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid var(--gray-05);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: var(--gray-20);
  }
}

input[type="radio"] {
  display: none;
  &:checked + label {
    border-color: var(--primary);
    font-weight: 600;
    color: var(--primary);
  }
}
</style>
