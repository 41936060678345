<template>
  <div>
    <h3 class="heading-4 my-4">
      {{ $t("trackingRuleNotificationsPage.almostThere") }}
    </h3>
    <p>{{ $t("trackingRuleNotificationsPage.wantToCreateRule") }}</p>

    <div>
      <div>
        <strong>{{ $t("trackingRuleNotificationsPage.ruleName") }}:</strong>
        {{ settings.ruleName }}
      </div>
      <div>
        <p v-if="ruleToAllContracts">
          {{ $t("trackingRuleNotificationsPage.allContractsIncluded") }}
        </p>
        <div v-else>
          <strong>
            {{ $t("trackingRuleNotificationsPage.totalContractsLinked") }}:
          </strong>
          {{ settings.contractIds.length }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <NbButton variant="secondary" @click="$emit('back')">
        {{ $t("back") }}
      </NbButton>
      <NbButton @click="create" :disabled="loading">
        {{ $t("save") }}
      </NbButton>
    </div>

    <Modal v-model="isCreatedRuleFeedbackOpen" @close="ruleContext?.success()">
      <CreatedRuleFeedback :ruleId="ruleId" />
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import { mapGetters, mapState } from "vuex";
import Modal from "@/components/modal/Modal.vue";
import CreatedRuleFeedback from "../CreatedRuleFeedback.vue";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";
import UserService from "../../../../services/UserService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();
const userService = new UserService();

export default {
  components: {
    NbButton,
    Modal,
    CreatedRuleFeedback,
  },
  inject: ["ruleContext"],
  data() {
    return {
      isCreatedRuleFeedbackOpen: false,
      loading: false,
      ruleId: null,
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.notificationRuleSettings,
    }),
    ...mapGetters("notificationRuleSettings", {
      ruleToAllContracts: "ruleToAllContracts",
    }),
    user() {
      return userService.getLoggedInUser();
    },
    contractIds() {
      return this.ruleToAllContracts ? ["all"] : this.settings.contractIds;
    },
  },
  methods: {
    mapNotifications() {
      const groups = this.settings.notifications;
      const result = [];

      for (const group of groups) {
        for (const checkpoint of group.checkpoints) {
          const activeNotifications = Object.keys(
            checkpoint.notifications
          ).filter((name) => checkpoint.notifications[name]);

          if (activeNotifications.length > 0) {
            for (const notification of activeNotifications) {
              result.push({
                checkpoint_zone: group.zoneCode,
                checkpoint_id: checkpoint.id,
                notification_type: notification,
              });
            }
          }
        }
      }

      return result;
    },
    async create() {
      try {
        this.loading = true;
        const { data } = await trackingRuleNotificationsService.create({
          tracking_notification_rule: {
            name: this.settings.ruleName,
            seller_ids: [],
            seller_id: null,
            contract_ids: this.contractIds,
            active: false,
            // last_update: "",
            platform_id: this.user.platform_id,
            tracking_notification_elements_attributes: this.mapNotifications(),
            rules_permission: "seller_settings",
          },
        });

        this.ruleId = data.data.id;
        this.isCreatedRuleFeedbackOpen = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
