<template>
  <div>
    <h3 class="heading-4 my-4">
      {{ $t("trackingRuleNotificationsPage.almostThere") }}
    </h3>
    <p>{{ $t("trackingRuleNotificationsPage.wantToUpdateRule") }}</p>

    <div>
      <div>
        <strong>{{ $t("trackingRuleNotificationsPage.ruleName") }}:</strong>
        {{ settings.ruleName }}
      </div>

      <div>
        <strong>{{ $t("trackingRuleNotificationsPage.applyRuleTo") }}</strong>
        {{ settings.applyRuleTo }}
      </div>

      <div v-if="settings.applyRuleTo === 'all_shipments'">
        <p>
          {{ $t("trackingRuleNotificationsPage.allContractsIncluded") }}
        </p>
      </div>
      <div v-else>
        <div>
          <strong
            >{{ $t("trackingRuleNotificationsPage.totalSellersLinked") }}:
          </strong>
          {{ settings.sellerIds.length }}
        </div>
        <div>
          <strong>
            {{ $t("trackingRuleNotificationsPage.totalContractsLinked") }}:
          </strong>
          {{ settings.contractIds.length }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <NbButton variant="secondary" @click="$emit('back')">
        {{ $t("back") }}
      </NbButton>
      <NbButton @click="update">
        {{ $t("update") }}
      </NbButton>
    </div>

    <Modal v-model="isUpdatedRuleFeedbackOpen">
      <UpdateRuleFeedback :rule-id="ruleId" />
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import { mapState, mapGetters } from "vuex";
import Modal from "@/components/modal/Modal.vue";
import UpdateRuleFeedback from "../UpdateRuleFeedback.vue";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";
import UserService from "../../../../services/UserService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();
const userService = new UserService();

export default {
  components: {
    NbButton,
    Modal,
    UpdateRuleFeedback,
  },
  inject: ["ruleContext"],
  data() {
    return {
      isUpdatedRuleFeedbackOpen: false,
      ruleId: null,
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.notificationRuleSettings,
    }),
    ...mapGetters("notificationRuleSettings", {
      ruleToAllSellers: "ruleToAllSellers",
      ruleToAllContracts: "ruleToAllContracts",
    }),
    user() {
      return userService.getLoggedInUser();
    },
    sellerIds() {
      return this.ruleToAllSellers ? ["all"] : this.settings.sellerIds;
    },
    contractIds() {
      return this.ruleToAllContracts ? ["all"] : this.settings.contractIds;
    },
  },
  methods: {
    mapNotifications() {
      const groups = this.settings.notifications;

      const result = [];

      for (const group of groups) {
        for (const checkpoint of group.checkpoints) {
          const activeNotifications = Object.keys(
            checkpoint.notifications
          ).filter((name) => checkpoint.notifications[name]);

          if (activeNotifications.length > 0) {
            for (const notification of activeNotifications) {
              result.push({
                checkpoint_zone: group.zoneCode,
                checkpoint_id: checkpoint.id,
                notification_type: notification,
              });
            }
          }
        }
      }

      return result;
    },
    async update() {
      try {
        this.loading = true;
        const { data } = await trackingRuleNotificationsService.update(
          this.ruleContext?.ruleId,
          {
            tracking_notification_rule: {
              name: this.settings.ruleName,
              contract_ids: this.contractIds,
              active: false,
              platform_id: this.user.platform_id,
              tracking_notification_elements_attributes:
                this.mapNotifications(),
              rules_permission: "seller_settings",
            },
          }
        );

        this.ruleId = data.data.id;
        this.isUpdatedRuleFeedbackOpen = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
